function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.cleanJson(_vm.extra_fields)),function(value,from){return _c('v-card',{key:from,staticClass:"mb-2"},[_c('v-card-text',{staticClass:"pa-2 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Campos extra do "+_vm._s(from)+" ")])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-form',{attrs:{"id":"formDadosMotorista"}},[_c('v-row',_vm._l((value),function(ref,key){
var cols = ref.cols;
var rest = objectWithoutProperties( ref, ["cols"] );
var args = rest;
return _c('v-col',_vm._b({key:key},'v-col',cols,false),[_c('v-text-field',_vm._b({attrs:{"value":_vm.getAtributoExtraField({
                  key: key,
                  from: from,
                })},on:{"input":function($event){return _vm.updateAtributoExtraField({
                  value: $event,
                  key: key,
                  from: from,
                })}}},'v-text-field',args,false))],1)}),1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }