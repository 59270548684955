<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Informações {{ exigePedido ? 'do pedido' : 'da carga' }}
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <form-item-pedido
            v-model="pedido"
            :edit="edit"
            :readonly="pedidoReadonly"
            :exigePedido="exigePedido"
            :obrigaXML="obrigaXML"
            @salvarPedido="salvarPedido"
            @setDadosXml="setDadosXml"
            ref="form-item-pedido"
          />
          <v-row>
            <v-col>
              <v-data-table
                :headers="visibleHeaders"
                :items="pedidos"
                sort-by="descricao"
                class="border"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small class="mr-2" @click="showDocumentos(item)"
                    >mdi-eye</v-icon
                  >
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                  <confirm-dialog @confirm="confirmDelete" />
                </template>
                <template v-slot:[`item.quantidade`]="{ item }">
                  {{ converteKg(item.quantidade) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog_documentos" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Documento</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog_documentos = false">
            Fechar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ModalPedidoDocumentos
              v-model="pedido_selecionado"
              :obrigaXML="obrigaXML"
              @close="closeDocumentos"
              v-if="dialog_documentos"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TipoAgendamento from '@/utils/agendamento'
import FormItemPedido from './FormItemPedido.vue'
import ModalPedidoDocumentos from './ModalPedidoDocumentos.vue'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import PatioApi from '@/services/patio/index'
import FileApi from '@/services/files/index'
import moment from 'moment'

export default {
  inject: {
    agendamento_xml: {
      from: 'agendamento_xml',
      default: () => {},
    },
    setCallbackXml: {
      from: 'setCallbackXml',
      default: () => {},
    },
  },
  data() {
    return {
      dialog_documentos: false,
      pedido_selecionado: null,
      pedidos: [],
      headers: [
        { text: 'Pedido', value: 'numero_pedido', visible: this.exigePedido },
        { text: 'Armazem', value: 'armazem.descricao', visible: true },
        { text: 'Produto', value: 'produto.descricao', visible: true },
        { text: 'SubProduto', value: 'subProduto.descricao', visible: true },
        { text: 'Quantidade (KG)', value: 'quantidade', visible: true },
        { text: 'Embalagem', value: 'embalagem.descricao', visible: true },
        { text: 'N° NFE', value: 'numero_nfe', visible: true },
        { text: 'Serie', value: 'numero_serie', visible: true },
        { text: 'Chave Acesso', value: 'chave_acesso', visible: true },
        { text: 'Data Emissão', value: 'dataEmissaoNf', visible: true },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          visible: true,
          class: 'actions',
        },
      ],
      editIndex: -1,
      pedidoASerExcluido: {},
      pedido: {
        criar: false,
        public_id: '',
        numero_pedido: '',
        numero_serie: '',
        numero_nfe: '',
        chave_acesso: '',
        dataEmissaoNf: '',
        quantidade: '',
        produto: {},
        subProduto: {},
        embalagem: {},
        armazem: {},
        quantidade_restante: Infinity,
        documentos: [],
      },
    }
  },
  components: {
    FormItemPedido,
    ConfirmDialog,
    ModalPedidoDocumentos,
  },
  watch: {
    'pedido.produto': {
      handler(val) {
        this.updateAtributoAgendamento({
          valor: val.subprodutos,
          nomeAtributo: 'subProdutos',
        })
      },
    },
  },
  computed: {
    ...mapState('agendamentos', [
      'agendamento',
      'tipoAgendamento',
      'pedidoSelecionado',
      'agendamentoSelecionado',
      'agendamento',
      'programacaoUnidade',
    ]),

    visibleHeaders() {
      return this.headers.filter((h) => {
        if (h.value === 'numero_pedido' && !this.exigePedido) {
          return false
        }
        return true
      })
    },

    configFornecedor() {
      return this.agendamento.fornecedor?.config
    },

    exigePedido() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.exigePedido
      )
    },
    obrigaXML() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.obrigaXML
      )
    },

    edit() {
      return this.editIndex > -1
    },
    pedidoReadonly() {
      return (
        this.tipoAgendamento > TipoAgendamento.Novo &&
        this.tipoAgendamento != TipoAgendamento.DaProgramacao
      )
    },
  },
  created() {
    this.setCallbackXml('pedidos', this.setDadosXml)
    this.setDadosXml()
    if (this.tipoAgendamento === TipoAgendamento.DoPedido) this.handlePedido()
    else if (
      this.tipoAgendamento === TipoAgendamento.Triagem ||
      this.tipoAgendamento === TipoAgendamento.EdicaoAgendado ||
      this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    )
      this.handlePedidosDoAgendamento()
    else if (this.tipoAgendamento === TipoAgendamento.DaProgramacao) {
      Object.assign(this.pedido, {
        produto: this.programacaoUnidade.produto,
        subProduto: this.programacaoUnidade.sub_produto,
      })
    }
  },
  methods: {
    ...mapActions('agendamentos', ['updateAtributoAgendamento']),
    ...mapActions('template', ['setDialogMessage']),
    async sendDocs(pedidos = null) {
      if (!pedidos) {
        pedidos = this.pedidos
      }
      let names = []
      let errors = {}
      await Promise.all(
        pedidos.map(async (pedido) => {
          await Promise.all(
            pedido.documentos.map(async (documento) => {
              if (!documento.necessity_send) {
                names.push(documento.attachment_key)
                return
              }
              let formData = new FormData()
              formData.append('description', documento.name)
              formData.append('file', documento)
              try {
                let data = await FileApi.postDocs(formData)
                if (data.attachment_key) {
                  names.push(data.attachment_key)
                }
                documento.necessity_send = false
                Object.assign(documento, data)
              } catch (error) {
                if (error.response) {
                  errors[documento.name] = error.response.data
                  return
                }
                if (error.message) {
                  errors[documento.name] = error.message
                  return
                }
                errors[documento.name] = error
              }
            })
          )
        })
      )
      if (Object.entries(errors).length) {
        this.errorMessage(errors)
      }
    },
    showDocumentos(item) {
      this.pedido_selecionado = item
      this.dialog_documentos = true
    },
    closeDocumentos() {
      this.dialog_documentos = false
    },
    async setDadosXml(xml = null) {
      if (!xml) {
        xml = this.agendamento_xml
      }
      const chave_acesso = xml?.chave_acesso
      const numero_serie = xml?.numero_serie
      const numero_documento = xml?.numero_documento
      const data_emissao = xml?.data_emissao
      const pedido = xml?.pedido
      const unidade_medida = xml?.unidade_medida
      const file = xml?.file
      // cfop
      // ncm
      if (chave_acesso) {
        this.pedido.chave_acesso = chave_acesso
      }
      if (numero_serie) {
        this.pedido.numero_serie = numero_serie
      }
      if (numero_documento) {
        this.pedido.numero_nfe = numero_documento
      }
      if (data_emissao) {
        this.pedido.dataEmissaoNf = data_emissao.split('T')[0]
      }
      if (pedido) {
        let quantidade = parseFloat(pedido)
        if (unidade_medida === 'TON' || unidade_medida === 'TN') {
          quantidade = (parseFloat(pedido) * 1000).toFixed(0)
        }
        this.pedido.quantidade = quantidade
      }
      if (file && this.pedido.documentos.length == 0) {
        if (!file.description) {
          file.description = file.name
        }
        this.pedido.documentos.push(file)
      }
      if (this.agendamento.tipo_documento === 'NF') {
        if (this.$refs['form-item-pedido']) {
          this.$refs['form-item-pedido'].getInfoChaveNfe()
        }
      }
    },
    handlePedido() {
      this.pedido = {
        numero_pedido: this.pedidoSelecionado.numero_pedido,
        numero_nfe: this.pedidoSelecionado.numero_nfe,
        produto: this.pedidoSelecionado.produto,
        subProduto: this.pedidoSelecionado.subProduto,
        embalagem: this.pedidoSelecionado.embalagem,
        numero_serie: this.pedidoSelecionado.numero_serie,
        chave_acesso: this.pedidoSelecionado.chave_acesso,
        dataEmissaoNf: this.pedidoSelecionado.dataEmissaoNf,
        quantidade_restante: this.pedidoSelecionado.quantidade_restante,
        public_id: this.pedidoSelecionado.public_id,
        documentos: [],
      }
    },

    converteKg(item) {
      return item
      // if (this.tipoAgendamento === TipoAgendamento.Novo) {
      //   return item
      // } else {
      //   return (parseFloat(item) * 1000).toFixed(0)
      // }
    },

    handlePedidosDoAgendamento() {
      this.agendamentoSelecionado.pedidos_agendados.forEach((pedido) => {
        const produto = { ...pedido.pedido.produto }
        const subProduto = { ...pedido.pedido.subProduto }
        const embalagem = { ...pedido.pedido.embalagem }
        this.pedidos.push({
          public_id: pedido.public_id,
          criar: false,
          numero_pedido: pedido.pedido.numero_pedido,
          numero_nfe: pedido.numero_nfe,
          numero_serie: pedido.numero_serie,
          chave_acesso: pedido.chave_acesso,
          dataEmissaoNf: pedido.dataEmissaoNf,
          quantidade: pedido.quantidade_agendada * 1000,
          produto,
          subProduto,
          embalagem,
          quantidade_restante: pedido.pedido.quantidade_restante,
          armazem: pedido.armazem,
          documentos: pedido.documentos ? pedido.documentos : [],
        })
      })
    },

    async salvarPedido(pedido) {
      if (pedido.dataEmissaoNf === '') {
        pedido.dataEmissaoNf = moment(new Date()).format('yyyy-MM-DD 00:00:00')
      }
      if (pedido.chave_acesso === '') {
        pedido.chave_acesso = '0'
      }
      if (pedido.numero_serie === '') {
        pedido.numero_serie = '0'
      }
      if (pedido.numero_nfe === '') {
        pedido.numero_nfe = '0'
      }
      if (!pedido.numero_pedido) {
        pedido.numero_pedido = new Date().getTime()
      }
      if (this.editIndex > -1) {
        this.pedidos.splice(this.editIndex, 1, pedido)
        this.editIndex = -1
        if (pedido.public_id !== '') {
          if (parseFloat(pedido.quantidade) > 1000) {
            pedido.quantidade = parseFloat(pedido.quantidade) / 1000
          } else {
            pedido.quantidade = parseFloat(pedido.quantidade)
          }

          pedido.dataEmissaoNf = pedido.dataEmissaoNf
            ? moment(pedido.dataEmissaoNf).format('yyyy-MM-DD 00:00:00')
            : 'None'
          pedido.criar = false
          console.log({ ...pedido })

          await this.sendDocs([pedido])

          console.log({ ...pedido })

          await PatioApi.editPedidosAgendados({
            ...pedido,
            quantidade_restante: pedido.cadencia_livre
              ? 0
              : pedido.quantidade_restante,
            documentos: pedido.documentos
              ? pedido.documentos.map((i) => i.attachment_key)
              : [],
          })

          const retorno = await PatioApi.getPedidosAgendados({
            agendamento: this.agendamentoSelecionado.public_id,
          })

          this.pedidos.forEach((item) => {
            item.quantidade_agendada =
              parseFloat(item.quantidade_agendada) * 1000
          })

          this.updateAtributoAgendamento({
            valor: retorno,
            nomeAtributo: 'pedidos_agendados',
          })
        }
      } else {
        pedido.criar = true
        this.pedidos.push({ ...pedido })

        this.updateAtributoAgendamento({
          valor: this.pedidos.filter((o) => o.criar),
          nomeAtributo: 'pedidos',
        })
      }
      this.$nextTick(() => {
        if (this.pedidoSelecionado) {
          this.handlePedido()
          Object.assign(this.pedido, {
            numero_nfe: '',
            numero_serie: '',
            chave_acesso: '',
            dataEmissaoNf: '',
          })
        } else {
          this.pedido = {
            criar: false,
            public_id: '',
            numero_pedido: '',
            numero_nfe: '',
            numero_serie: '',
            chave_acesso: '',
            produto: {},
            subProduto: {},
            embalagem: '',
            quantidade_restante: Infinity,
            quantidade: '',
            documentos: [],
            dataEmissaoNf: '',
          }
        }
      })

      this.setDadosXml()
    },
    editItem(item) {
      let pedidoEditavel = { ...item }
      this.editIndex = this.pedidos.indexOf(item)
      Object.assign(this.pedido, pedidoEditavel)
    },
    deleteItem(item) {
      this.pedidoASerExcluido = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir esse pedido?',
      })
    },

    async confirmDelete() {
      try {
        if (this.pedidoASerExcluido.public_id !== '') {
          await PatioApi.deletePedidosAgendados(this.pedidoASerExcluido)
        }
        this.pedidos.splice(this.pedidos.indexOf(this.pedidoASerExcluido), 1)
        this.updateAtributoAgendamento({
          valor: this.pedidos,
          nomeAtributo: 'pedidos',
        })
        this.successMessage('Pedido excluido com sucesso!')
      } catch (err) {
        this.errorMessage(err.response.data)
      }
    },
  },
}
</script>

<style>
.actions {
  white-space: nowrap;
}
</style>
