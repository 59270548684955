<template>
  <div>
    <v-card
      class="mb-2"
      v-for="(value, from) in cleanJson(extra_fields)"
      v-bind:key="from"
    >
      <v-card-text class="pa-2 border-bottom">
        <h3 class="subtitle blue-grey--text text--darken-2 font-weight-regular">
          Campos extra do {{ from }}
        </h3>
      </v-card-text>
      <v-card-text class="pa-5 border-bottom">
        <v-form id="formDadosMotorista">
          <v-row>
            <v-col
              v-for="({ cols, ...args }, key) in value"
              v-bind:key="key"
              v-bind="cols"
            >
              <v-text-field
                v-bind="args"
                :value="
                  getAtributoExtraField({
                    key: key,
                    from: from,
                  })
                "
                @input="
                  updateAtributoExtraField({
                    value: $event,
                    key: key,
                    from: from,
                  })
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { cleanJson } from '@/utils'

export default {
  props: {
    extra_fields: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      types: {
        CHAR: 'text',
        INT: 'number',
      },
    }
  },
  components: {},
  mixins: [],
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),
  },
  async created() {},
  methods: {
    ...mapActions('agendamentos', [
      'updateAtributoAgendamento',
      'updateAtributo',
    ]),
    cleanJson,
    getType(type) {
      if (!(type in this.types)) return 'text'
      return this.types[type]
    },
    getAtributoExtraField({ key, from }) {
      if (!('extra' in this.agendamento)) return ''
      if (!(from in this.agendamento.extra)) return ''
      if (!(key in this.agendamento.extra[from])) return ''
      return this.agendamento.extra[from][key]
    },
    updateAtributoExtraField({ value, key, from }) {
      let extra = {}
      if ('extra' in this.agendamento) {
        extra = this.agendamento.extra
      }
      console.log(extra, value, key, from)
      if (!(from in extra)) {
        extra[from] = {}
      }
      // if (!(key in extra[from])) {
      //   extra[from][key] = ''
      // }
      extra[from][key] = value
      this.updateAtributoAgendamento({
        valor: extra,
        nomeAtributo: 'extra',
      })
    },
  },
  watch: {},
}
</script>
